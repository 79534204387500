var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-pool-vehicles"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.serverItemsLength,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.registration",fn:function(ref){
var item = ref.item;
return [(item.details)?_c('span',[_vm._v(_vm._s(item.details.registrationNumber))]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.vin",fn:function(ref){
var item = ref.item;
return [(item.details)?_c('span',[_vm._v(_vm._s(item.details.vinNumber))]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.details)?_c('span',[_vm._v(_vm._s(item.details.vehicleBrand)+" "+_vm._s(item.details.vehicleModel))]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.mileage",fn:function(ref){
var item = ref.item;
return [(item.mileages && item.mileages[0])?_c('span',[_vm._v(_vm._s(_vm.formatNumber.kilometer(item.mileages[0].mileage)))]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.damageReportPhoneNumber",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row"},[_c('o-damage-modal',{attrs:{"vehicle":item},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"warning"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-hazard-lights")])],1)]}}],null,true)}),(item.damageReportPhoneNumber)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"success","href":("tel:" + (encodeURIComponent(item.damageReportPhoneNumber)))}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-phone")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.damageReportPhoneNumber))])]):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showCalendar(item)}}},[_c('v-icon',[_vm._v("mdi-calendar")])],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"1024px","fullscreen":_vm.$vuetify.breakpoint.smAndDown,"scrollable":"","persistent":""},model:{value:(_vm.calendar.open),callback:function ($$v) {_vm.$set(_vm.calendar, "open", $$v)},expression:"calendar.open"}},[(_vm.calendar.vehicle)?_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_c('span',[_vm._v("Kalendarz pojazdu")]),(_vm.calendar.vehicle.details)?_c('v-chip',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.calendar.vehicle.details.registrationNumber))]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.calendar.open = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('o-vehicle-reservation',{attrs:{"id":_vm.calendar.vehicle.id}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }