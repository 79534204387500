























































import useApi from "@/use/api";
import useNumber from "@/use/number";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";

export default defineComponent({
  components: {
    OVehicleReservation: () =>
      import("@/components/organisms/o-vehicle-reservation.vue"),
    ODamageModal: () => import("@/components/organisms/o-damage-modal.vue"),
  },
  setup(_, { root }) {
    const { axiosInstance } = useApi({ root });
    const { formatNumber } = useNumber();

    const headers = computed(() => [
      { value: "registration", text: "Numer rejestracyjny", sortable: false },
      { value: "vin", text: "Numer VIN", sortable: false },
      { value: "name", text: "Model/marka", sortable: false },
      { value: "mileage", text: "Przebieg", sortable: false },
      {
        value: "damageReportPhoneNumber",
        text: "Zgłąszanie szkód",
        sortable: false,
      },
      { value: "actions", text: "", sortable: false, width: 1 },
    ]);

    const items = ref([]);

    const serverItemsLength = ref(0);

    const options = ref({
      page: 1,
      itemsPerPage: 10,
    });

    const fetchData = () => {
      axiosInstance
        .get("vehicle", {
          params: {
            filter: {
              pool: true,
              page: options.value.page ?? undefined,
              itemsPerPage: options.value.itemsPerPage ?? undefined,
            },
          },
        })
        .then(({ data: { vehicles, total } }) => {
          items.value = vehicles;
          serverItemsLength.value = total;
        });
    };

    onMounted(fetchData);
    watch(options, fetchData, { deep: true });

    const calendar = reactive({
      open: false,
      vehicle: null as string | null,
    });

    const showCalendar = (vehicle?: any) => {
      if (vehicle) {
        calendar.vehicle = vehicle;
        calendar.open = true;
      }
    };

    return {
      formatNumber,

      headers,
      items,
      serverItemsLength,
      options,

      calendar,
      showCalendar,

      encodeURIComponent,
    };
  },
});
